import React, { useState } from 'react'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import About from '../components/views/About/About'

export default function CodebookTemplate({
  pageContext: { indicators, areas, placeNames },
}) {
  const [page, setPage] = useState(null)
  return (
    <Layout page={page}>
      <SEO
        title="About"
        description="View documentation and contributors for HIV Policy Lab"
      />
      <About
        {...{
          setPage,
          firstView: 'codebook',
          indicators,
          areas,
          placeNames,
        }}
      />
    </Layout>
  )
}
